<script setup lang="ts">
</script>

<template>
  <div class="nav-pill">
    <slot />
  </div>
</template>

<style lang="sass">
@import '~/assets/styles/generated/variables.sass'

// TODO: adding a backdrop filter here doesn't work with chrome, which only allows one layer
// of backdrop filters, so find another way to implement this? Or make the dropdown a
// sibling rather than a child
.nav-pill
  app-region: no-drag
  box-shadow: 0px 0px 10px 0px rgba(15, 15, 15, 0.75)
  @apply bg-[#{$pill-bg-default}] outline-[#{$pill-b-outline}] h-[#{$pill-h-default}] p-[#{$pill-m-button}] pointer-events-auto flex items-center justify-center outline rounded-full z-navigation-layer outline-1

  &> div.pill-item + div.pill-item
    @apply -ml-1.5
</style>
